@mixin base-button {
    padding: 20px;
    border-radius: 50%;
    border: 1px solid white;
    margin: 0px 30px;
}
.trending-container {
    // padding: 100px 200px;
    padding-top: 152px;
    padding-bottom: 112px;
    h1 {
        margin-bottom: 40px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    &__listHotties {
        display: flex;
        margin-bottom: 50px;
        align-items: center;
        position: relative;
        height: 100%;
        margin-top: 40px;
        .item {
            flex: 1;
            transition: all 0.3s;
        }

        &__middle {
            z-index: 1;
            height: 100%;
            transform: scale(1.2);
        }
        &__left {
            // z-index: -1;
            transform: translateX(40px);
        }

        &__right {
            // z-index: -1;
            transform: translateX(-40px);
        }
    }
    &__buttons {
        display: flex;
        margin-top: 25px;
        .button-trending-hotties {
            margin: 0 18px;
            width: 80px;
            height: 80px;
            cursor: pointer;
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .trending-container {
        padding-top: 50px;

        &__listHotties {
            width: 100%;
        }
        &__buttons {
            margin-top: 20px;
            .button-trending-hotties {
                margin: 0 15px;
                width: 70px;
                height: 70px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .trending-container {
        padding: 50px 10px;

        h1 {
            align-self: flex-start;
            font: normal normal bold 24px/29px Montserrat;
        }
        &__listHotties {
            width: 100%;
            margin-bottom: 15px;

            &__left, &__right {
                display: none;
            }

            &__middle {
                transform: scale(1);
            }
        }
        &__buttons {
            margin-top: 10px;
            .button-trending-hotties {
                margin: 0 10px;
                width: 50px;
                height: 50px;
            }
        }
    }
}
