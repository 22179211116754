.reason-item-container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    width: 100%;
    .reason-title {
        text-align: left;
        font: normal normal 600 12px/18px Montserrat;
        letter-spacing: 0.48px;
        color: #ffffff;
    }
    .hr-reason {
        background: #ff3e3e 0% 0% no-repeat padding-box;
        width: 3%;
        height: 2px;
        border-style: none;
        margin-left: 0;
        position: relative;
        top: -3px;
    }
    .reason-description {
        text-align: left;
        font: normal normal medium 12px/15px Montserrat;
        letter-spacing: 0px;
        color: #808080;
        position: relative;
        top: -4px;
        position: relative;
        top: -10px;
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .reason-item-container {
        .hr-reason {
            position: relative;
            top: -6px;
        }
        .reason-title {
            font-size: 9px;
        }
    }
}
@media screen and (max-width: 576px) {
    .reason-item-container {
        align-items: center;
        .hr-reason {
            position: relative;
            top: -6px;
            margin-left: auto;
        }
    }
}
