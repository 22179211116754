.connect-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
    opacity: 0;
    visibility: hidden;
    &__form {
        background-color: rgb(35, 38, 48);
        width: 400px;
        padding: 25px;
        border-radius: 10px;
        .subtitle {
            font-size: 18px;
            font-weight: 595;
        }
        .child__container {
            display: flex;
            flex-direction: row;
            margin: 20px 0px;
            .network-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;
                border: 1px solid rgb(75 85 99);
                flex: 1;
                border-radius: 10px;
                cursor: pointer;
                &.active {
                    background-color: rgb(75 85 99);
                }
                &:first-child {
                    margin-right: 20px;
                }
                img {
                    width: 40px;
                    margin-right: 15px;
                }
            }
        }
        .wallet-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
            border-radius: 10px;
            flex: 1;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
                background: rgb(75 85 99);
            }
            img {
                width: 60px;
                margin-bottom: 10px;
            }
        }
    }
}
