.navigate-mobile-container {
    display: none;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #1b1b1d;
    color: white;
    padding: 15%;
    box-sizing: border-box;
    justify-content: space-around;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 101;
    transition: all 0.5s;

    #close-icon {
        display: none;
    }
    .item {
        font-size: 15px;
        color: white;
        text-decoration: none;
        font-weight: bolder;
        text-transform: uppercase;
    }
    // .close-icon-label:checked ~ .item {
    //     display: none;
    // }
}
// @media screen and (max-width: 595px) {
//     .navigate-mobile-container {
//         display: flex;
//         left: 0;
//     }
// }
