@import '../../styles/index.scss';

.bg-header {
    position: sticky;
    top: 0;
    z-index: 99;

    .container {
        background: #212127 0% 0% no-repeat padding-box;
    }

    .header-mobile-dropdown {
        position: fixed;
        display: none;
        width: 0px;
        height: calc(100vh - 54px);
        z-index: 100;
        overflow: hidden;
        transition: width 1s;

        .overlay {
            top: unset;
            opacity: 1;
        }

        .list-menu-container {
            position: relative;
            display: flex;
            flex-direction: column;
            background: #212127;
            height: 100%;
            gap: 50px;
            overflow: auto;

            .list-menu {
                display: flex;
                flex-direction: column;
                padding-left: 45px;
                gap: 50px;

                &__item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font: normal normal 600 20px/24px Montserrat;
                    letter-spacing: 1.4px;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: #fff;
                    cursor: pointer;
    
                    &:nth-child(1) {
                        margin-top: 45px
                    }
    
                    .coming-soon {
                        @include base-comming-soon();
                        text-align: center;
                        font: normal normal 600 12px/22px Montserrat;
                        letter-spacing: 0px;
                        color: #939393;
                        padding: 2px 10px;
                        margin-left: 10px;
                        text-transform: uppercase;
                    }
                }
            }

            .list-menu-icon {
                margin-top: auto;
                margin-bottom: 36px;
                display: flex;
                justify-content: center;
                width: 100%;
                color: red;
                gap: 33px;
                a {
                    color: inherit;
                }
                .icon {
                    width: 22px;
                    height: 23px;
                }
            }
        }
    }

    .active {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
}

.header {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    padding: 15px 0;
    justify-content: space-between;
    .menu {
        display: none;
    }
    .search-bar-container {
        width: 30%;
        margin: 0px 50px;
    }
    img {
        width: 175px;
        margin-right: 2%;
    }
    .nav-wrapper {
        margin-right: 10px;
        flex: 40%;
        display: flex;
        justify-content: space-around;

        &__item {
            display: flex;
            align-items: center;

            .item {
                font-size: 15px;
                color: white;
                text-decoration: none;
                font-weight: bolder;
                text-transform: uppercase;
            }

            .coming-soon {
                @include base-comming-soon();
                text-align: center;
                font: normal normal 600 12px/22px Montserrat;
                letter-spacing: 0px;
                color: #939393;
                padding: 2px 10px;
                margin-left: 10px;
                text-transform: uppercase;
            }
        }
    }
    .btn-primary {
        width: 50px;
        @include base-button();
        font-weight: bold;
        font: normal normal 600 14px/18px Montserrat;
        text-transform: uppercase;
        padding: 11px 38px;
        border-radius: 4px;
        white-space: nowrap;
    }
    .btn-register {
        font-weight: bold;
        font: normal normal 600 14px/18px Montserrat;
        text-transform: uppercase;
        padding: 11px 38px;
        border-radius: 4px;
        border: 1px solid white;
        margin-left: 20px;
        cursor: pointer;
    }
    .account-img {
        background-color: black;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        &__dropdown {
            position: absolute;
            top: 100%;
            right: -10px;
            padding-top: 19px;
            z-index: 10;

            &--container {
                background: #212127 0% 0% no-repeat padding-box;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                transition: all 0.3s;
                height: 0px;
                overflow: hidden;
                .nav-drop {
                    padding: 15px 40px;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: capitalize;
                    transition: all 0.3s;
                    display: block;
                    color: white;
                    text-decoration: none;
                    cursor: pointer;
                    &:last-child {
                        border-top: 1px solid rgb(77, 77, 77);
                    }
                    &:hover {
                        background-color: rgb(77, 77, 77);
                    }
                }
            }
        }

        .dropdown-open {
            height: 95px;
        }
        // &:hover .account-img__dropdown--container {
        //     height: 95px !important;
        // }
    }

    .wallet-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .select-network {
            margin-right: 10px;
            padding: 5px 10px;
            color: white;
            background-color: transparent;
            border-radius: 20px;
        }
        .account-container {
            padding: 5px 10px;
            border: 1px solid white;
            border-radius: 20px;
            cursor: pointer;
        }
    }
}
.eap-msg {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding: 10px 50px;

    &.msg-notice {
        background-color: #5a67ff;
        color: #fff;
    }

    &.msg-error {
        background-color: #FF686B;
        color: #fff;
    }

    &.msg-primary {
        background-color: #ff3e3e;
        color: #fff;
    }

    span, a {
        color: inherit;
        text-decoration: none;
        border-bottom: 2px solid #fff;
        cursor: pointer;
    }
}
@media screen and (min-width: 1120px) and (max-width: 1440px) {
    .header {
        .search-bar-container {
            width: 30%;
            margin: 0px 20px;
        }
        .nav-wrapper {
            padding: 0px 20px;
            margin-right: 20px;

        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1119px) {
    .header {
        .search-bar-container {
            width: 25%;
            margin: 0px 20px;
        }
        .nav-wrapper {
            padding: 0px 20px;
            margin-right: 20px;

            &__item {
                .item {
                    font-size: 12px;
                }

                .coming-soon {
                    display: none;
                }
            }

            .btn-primary {
                font-size: 12px;
                padding: 5px 20px;
            }
            .wallet-container {
                .select-network {
                    font-size: 12px;
                }
                .account-container {
                    font-size: 12px;
                }
                .btn-register {
                    font-size: 12px;
                }
            }
        }
    }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
    .header {
        .search-bar-container {
            width: 45%;
            margin: 0px 20px;
        }
        .nav-wrapper {
            display: none;
        }
    }
    .header-mobile-dropdown {
        width: 400px !important;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .header {
        .search-bar-container {
            display: none;
        }
        .nav-wrapper {
            display: none;
        }
        .btn-primary {
            font-size: 10px;
            padding: 5px 20px;
        }
    }
    .header-mobile-dropdown {
        width: 400px !important;
    }
}
@media screen and (max-width: 576px) {
    .header {
        .search-bar-container {
            display: none;
        }
        .nav-wrapper {
            display: none;
        }
        .btn-primary {
            font-size: 10px;
            padding: 5px 20px;
        }
    }
    .header-mobile-dropdown {
        top: 54px;
        left: 0px;
    }
}
