@import '../../../../index.scss';

.creator-details {
    &__row-buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
    }

    &__get-access-pass {
        background-color: #1b1b1c;
        padding: 20px 0;
        display: flex;
        justify-content: center;

        a {
            text-decoration: none;
            color: #fff;
        }

        button {
            @include base-button();
            border-radius: 1000px;
            padding: 17.5px 92px;
            font-weight: 600;
            font-size: 15px;
            border: none;
            color: #fff;
        }
    }

    &__twitter {
        margin: 45px 0 10px;
        padding-bottom: 5rem;

        h2 {
            font-size: 36px;
            font-weight: 600;
        }

        .content {
            max-width: 550px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 595px) {
        &__row-buttons {
            display: none;
        }

        &__twitter {
            margin-top: 16px;

            h2 {
                display: none;
            }
        }
    }
}
