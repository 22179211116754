.process-get-access-pass-modal {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    z-index: 1000;

    .content {
        width: 440px;
        height: 461px;
        background-color: #212127;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        border-radius: 20px;
        position: relative;

        .loading {
            width: 31px;
            height: 31px;
            border: 3px solid #ff3e3e;
            border-top: 3px solid #212127;
            border-radius: 50%;
            animation: spinner 1.2s linear infinite;
        }

        h2 {
            margin: 15px 0 8px;
            font-size: 24px;
            font-weight: 600;
        }

        p {
            font-size: 13px;
            color: #838383;
        }

        .btn-done {
            background: transparent linear-gradient(98deg, #FF3E3E 0%, #FF8200 100%) 0% 0% no-repeat padding-box;
            font-size: 15px;
            font-weight: 600;
            color: #fff;
            border-radius: 999px;
            border: none;
            width: 325px;
            height: 51px;
            cursor: pointer;
            margin-top: 85px;
        }

        .btn-close {
            position: absolute;
            top: 15px;
            right: 15px;
            border: none;
            padding: 0;
            background: transparent;
            color: #838383;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 595px) {
        .content {
            width: 331px;
            height: 333px;

            .btn-done {
                width: 291px;
                height: 47px;
                margin-top: 45px;
            }

            .btn-close {
                display: none;
            }

            img {
                width: 120px;
            }
        }
    }
}

@keyframes spinner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
