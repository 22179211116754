@mixin borderStyle {
    border: 6px solid black;
    border-radius: 25px;
}
.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .card-image {
        width: 100%;
        height: 100%;
        @include borderStyle();
        opacity: 1;
    }

    .card-name {
        white-space: nowrap;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 10px;
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: normal normal 900 26px/32px Montserrat;
        display: flex;
        flex-direction: row;
        letter-spacing: 1.5px;
        color: #ffffff;
        opacity: 1;
        .watshot-icon {
            width: 20px;
            height: 31px;
            margin-right: 10px;
        }
        .forward-icon {
            font-size: 20px;
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .container {
        .card-name {
            font: normal normal bold 14px/20px Montserrat;
            .forward-icon {
                font-size: 14px;
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .container {
        .card-name {
            left: 15px;
            bottom: 24px;
            font: normal normal bold 26px/32px Montserrat;
        }
    }
}
