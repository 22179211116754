$bottom-color: #212127;

.card {
    min-width: 227px !important;
    height: 400px;
    display: flex;
    flex-direction: column;
    margin-right: 54px;
    border-radius: 10px;
    overflow: hidden;

    &__top {
        width: 100%;
        height: 90%;
        background-color: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__bottom {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10%;
        background-color: $bottom-color;
    }
}
@media screen and (max-width: 576px) {
    .card {
        min-width: 271px !important;
        height: 426px;
        margin-right: 5%;
        align-items: center;

        &__bottom {
            // border-bottom-left-radius: 10px;
            // border-bottom-right-radius: 10px;
            // width: 89%;

            padding: 5px;
            font-size: 9px;
        }
    }
}
