.search-header-container {
    position: relative;
    padding: 10px;
    // width: 100%;
    height: 100%;
    background: #1b1b1d 0% 0% no-repeat padding-box;
    border-radius: 20px;

    .search-header {
        display: flex;
        align-items: center;
        flex-direction: row;
    
        input {
            width: 90%;
            border: none;
            background-color: transparent;
            color: white;
            margin-right: auto;

            &::placeholder {
                color: #7C7C7C;
                font: normal normal normal 15px/19px Montserrat;
            }
        }
        .search-icon {
            color: #5d5d5d;
        }
        .clear-icon {
            color: #5d5d5d;
            cursor: pointer;
        }
    }
    
    .search-header-dropdown {
        position: absolute;
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        left: 0;
        margin-top: 10px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top: 1px solid #c4c4c4;

        &__container {
            min-height: 200px;
            max-height: 200px;
            overflow: auto;
            padding: 0px 15px;

            &::-webkit-scrollbar {
                width: 3px;
            }
            
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #C4C4C4; 
            }

            .search-option-list {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 10px;
                margin-top: 5px;
                overflow-x: hidden;
                position: relative;
                padding-bottom: 5px;
    
                .search-option {
                    padding: 5px 15px;
                    height: 24px;
                    background: #C4C4C4 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    font: normal normal 600 13px/22px Montserrat;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    cursor: pointer;
                }
    
                .active {
                    background: #3C3C3C 0% 0% no-repeat padding-box;
                }
    
                &:hover {
                    overflow-x: auto;
                }
    
                &::-webkit-scrollbar {
                    height: 6px;
                }
                
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #C4C4C4; 
                }
            }
    
            .search-result-list {
                &__item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin: 15px 0px;
                    cursor: pointer;
                    text-decoration: none;

                    &:last-child {
                        margin-bottom: 0;
                    }
    
                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
    
                    .search-result-name {
                        font: normal normal 600 15px/19px Montserrat;
                        color: #000;
                    }

                    &:hover {
                        background: #F5F5F5 0% 0% no-repeat padding-box;
                    }
                }
            }
        }

        .gap {
            min-height: 15px;
        }
    }
}
.expand {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 99;

    .search-header {
        input {
            color: #000;
        }
    }
}

@media screen and (max-width: 768px) {
    .search-icon {
        font-size: smaller;
    }

    input::placeholder {
        font-size: xx-small;
    }

    .search-header-container {
        .search-header-dropdown {
            .search-option-list {
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}
