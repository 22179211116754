.footer-container {
    width: 100vw;
    background: #212127 0% 0% no-repeat padding-box;
    padding-top: 70px;

    .footer-wrapper {
        max-width: 1440px;
        padding-left: 120px;
        padding-right: 120px;
        margin: 0 auto;
        box-sizing: border-box;
        background: #212127 0% 0% no-repeat padding-box;
        display: flex;
        flex-direction: column;
        &__list-navigate {
            display: flex;
            padding-bottom: 50px;
            .item-navigate-container {
                flex: 3;
                display: flex;
                justify-content: center;
            }
        }
        &__list-icon-media {
            display: flex;
            justify-content: center;
            padding-bottom: 29px;
            color: red;
            a {
                color: inherit;
            }
            .icon {
                margin-right: 50px;
                width: 22px;
                height: 23px;
            }
        }
        &__copyright {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .footer-container {
        .footer-wrapper {
            padding: 10px;
            &__list-icon-media {
                padding-bottom: 20px;

                .icon {
                    margin-right: 20px;
                    font-size: medium;
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .footer-container {
        .footer-wrapper {
            padding: 10px;
            &__list-navigate {
                flex-direction: column;
                gap: 50px;
                .item-navigate-container {
                    align-items: center;
                }
            }
            &__list-icon-media {
                padding-bottom: 20px;

                .icon {
                    margin-right: 20px;
                    font-size: medium;
                }
            }
        }
    }
}
