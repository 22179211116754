@import './styles/index.scss';

* {
  font-family: 'Montserrat';
}

html {
  overflow-x: hidden;

  &::-webkit-scrollbar {
      width: 6px;
  }
  
  &::-webkit-scrollbar-track {
      border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #464646; 
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  background-color: black;
  color:white;
}
body > iframe {
  display: none;
}

p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.Toastify__toast-container {
  z-index: 99999999;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: bold;
  cursor: default;
}

input {
  &:focus {
    outline: none;
  }
}


@media (max-width: 767px) {
  .slick-dots {
    bottom: 40px;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
  }
}