@import '../../styles/index.scss';

.eap {
    overflow-x: hidden;

    .container__content {
        min-height: 95vh;
    }

    &__vip {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #212127 0% 0% no-repeat padding-box;
        text-align: center;
        padding: 60px 10px;
        border-radius: 12px;

        .title {
            font-size: 24px;
            margin: 10px 5px;
        }
        .content {
            font-size: 15px;
            margin: 4px 0 0;
        }
        img {
            width: 55px;
            margin-top: -20px;
        }
    }

    &__hero {
        padding-top: 45px;

        img {
            width: 250px;
            margin: 0 auto;
            display: block;
        }
    }

    &__status {
        background-color: #ff6300;
        font-size: 14px;
        font-weight: 600;
        padding: 6px 21px;
        color: #fff;
        border: none;
        border-radius: 6px;
        display: block;
        margin: 0 auto;
    }

    &__heading {
        text-align: center;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 600;
        margin: 12px 0 15px;
    }

    &__btn-buy {
        color: #fff;
        font-size: 15px;
        display: flex;
        justify-content: center;
        margin: 45px auto 80px;
    }

    &__benefits {
        background-color: #121212;
        border-radius: 12px;
        padding: 30px;
        margin: 80px 0;

        h2 {
            color: #272727;
            font-size: 62px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 35px;
        }

        .benefits {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            padding: 0 15rem;

            &-item {
                display: flex;
                align-items: center;
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                padding: 11px 0;

                &:nth-child(2n + 1) {
                    padding-left: 8rem;
                }

                &:nth-child(2n) {
                    padding-left: 2rem;
                }

                span {
                    margin-left: 5px;
                }
            }
        }
    }

    &__posters {
        padding: 80px 0 160px;

        h2 {
            text-align: center;
            color: #fff;
            font-size: 42px;
            font-weight: 600;
            margin-bottom: 41px;
            text-transform: uppercase;
        }

        h3 {
            display: none;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 5px;
            text-align: center;

            &:nth-child(2) {
                transform: translateX(-40px);
            }

            &:nth-child(3) {
                transform: translateX(0);
            }

            &:nth-child(4) {
                transform: translateX(60px);
                margin-bottom: 30px;
            }
        }

        .posters {
            display: flex;
            justify-content: center;
            align-items: center;

            &-item {
                margin: 0 25px;
                position: relative;

                &:after {
                    content: '-10%';
                    display: block;
                    width: 37px;
                    height: 37px;
                    line-height: 37px;
                    border: 2px solid #fff;
                    text-align: center;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    font-size: 10px;
                    font-weight: 600;
                    border-radius: 50%;
                    background-color: #5a67ff;
                }

                img {
                    border-radius: 15px;
                }
            }
        }
    }

    &__loading {
        width: 31px;
        height: 31px;
        border: 3px solid #ff3e3e;
        border-top: 3px solid #212127;
        border-radius: 50%;
        animation: spinner 1.2s linear infinite;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @keyframes spinner {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @media screen and (max-width: 1338px) {
        &__benefits {
            .benefits {
                &-item {
                    padding-left: 2rem !important;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        &__benefits {
            .benefits {
                padding: 0 10rem;
            }
        }
    }

    @media screen and (max-width: 992px) {
        &__benefits {
            .benefits {
                padding: 0;
            }
        }
    }

    @media screen and (max-width: 576px) {
        &__vip {
            margin-left: 1rem;
            margin-right: 1rem;

            button {
                font-size: 15px;
                width: 300px;
                height: 50px;
            }
        }

        &__status {
            img {
                width: 200px;
            }
        }

        &__heading {
            font-size: 20px;
            margin: 7px 10px;
        }

        &__btn-buy {
            padding: 16px 115px;
            font-size: 16px;
        }

        &__benefits {
            padding: 10px;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 40px;

            h2 {
                margin-top: 44px;
                font-size: 48px;
            }

            .benefits {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(4, auto);
                padding: 0 1rem;

                &-item {
                    padding-left: 0 !important;
                }
            }
        }

        &__posters {
            padding-top: 40px;
            padding-bottom: 40px;

            h2 {
                display: none;
            }

            h3 {
                display: block;
            }

            .posters {
                overflow-x: scroll;
                justify-content: flex-start;
                padding-bottom: 2rem;

                &::-webkit-scrollbar {
                    height: 6px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #464646;
                }
            }
        }

        &__loading {
            margin: 5rem auto;
        }
    }
}
