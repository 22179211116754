@import '../../../../styles/index.scss';
.wrapper {
    border-top: 1px solid #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
}
.hottest-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 300px;
    @include base-container();
    &__title {
        @include base-main-title();
    }
    &__list-card {
        width: 70%;
        display: flex;
        flex-direction: column;
    }
    &__commingsoon {
        @include base-comming-soon();
        font: normal normal normal 14px/22px Montserrat;

        svg {
            margin-right: 4px;
        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1191px) {
    .hottest-container {
        padding: 5%;
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .hottest-container {
        padding-left: 37px;
        padding-right: 37px;
    }
}
@media screen and (max-width: 576px) {
    .hottest-container {
        padding: 48px 20px;
        &__title {
            font: normal normal bold 24px/29px Montserrat;
        }
        &__list-card {
            width: 100%;
        }
        &__commingsoon {
            font: normal normal normal 13px/22px Montserrat;
        }
    }
}
