@mixin base-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $button-base-color;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Montserrat';
}
@mixin base-main-title {
    width: 100%;
    text-align: left;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal 600 32px/80px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    line-height: normal;
    .top {
        color: #454545;
    }
    padding-bottom: 20px;
}
@mixin base-comming-soon {
    display: flex;
    align-items: center;
    width: fit-content;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 4px 10px;
    text-align: left;
    font-size: 14px;
    font-weight: medium;
    letter-spacing: 0px;
    color: #ff8200;
    .icon-coming-soon {
        width: 13px;
        height: 13px;
        margin-right: 6px;
    }
}
@mixin base-container {
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100vw;
}
@mixin base-input-creator-page {
    box-sizing: border-box;
    padding: 10px;
    text-align: left;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background-color: transparent;
    color: #939393;
    margin-bottom: 11px;
    width: 100%;

    .input::placeholder {
        color: #ffffff;
    }
}
@mixin btn-item {
    margin-right: 18px;
    background: #3c3c3c 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 14px/22px Montserrat;
    letter-spacing: 0px;
    color: #727272;
    opacity: 1;

    padding: 6px 12px;
}
