.bio {
    padding: 5rem 0;
    border-bottom: 1px solid #4d4d4d;

    h2 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    &__row-stats {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;

        .stats-item {
            background-color: #1b1b1c;
            border-radius: 15px;
            padding: 30px 0;
            text-align: center;
            width: 23%;

            h5 {
                font-size: 16px;
                font-weight: bold;
                color: rgba($color: #fff, $alpha: 0.4);
                margin-top: 0;
                margin-bottom: 5px;
            }

            p {
                font-size: 28px;
                font-weight: medium;
                color: #fff;
            }
        }
    }

    &__text {
        white-space: pre-line;
        font-size: 14px;
    }

    @media screen and (max-width: 950px) {
        &__row-stats {
            flex-wrap: wrap;

            .stats-item {
                width: 48%;
                margin-bottom: 15px;
            }
        }
    }

    @media screen and (max-width: 595px) {
        h2 {
            font-size: 16px;
            margin-bottom: 10px;
        }

        &__row-stats {
            margin-bottom: 25px;
        }

        .stats-item {
            h5 {
                font-size: 13px;
            }

            p {
                font-size: 19px;
            }
        }

        &__text {
            font-weight: 300;
            font-size: 14px;
        }
    }
}
