@import '../../../../styles/index.scss';
.follow-container {
    padding: 100px;
    padding-right: 0px;

    display: flex;
    flex-direction: column;

    &__title {
        @include base-main-title();
        margin-bottom: 53px;
    }
    &__hotties-wrapper {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-bottom: 1rem;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            height: 6px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #464646; 
        }
    }
    &__button-rows {
        margin: 40px auto 30px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        .btn-item {
            @include btn-item();

            width: fit-content;
            border-style: none;
            cursor: pointer;
        }
        .active {
            background-color: #ff3e3e;
            color: #ffffff;
        }
    }
    &__commingsoon {
        @include base-comming-soon();
        margin: 0 auto;

        svg {
            margin-right: 4px;
        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1440px) {
    .follow-container {
        padding: 50px;
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .follow-container {
        padding: 50px 20px;
    }
}
@media screen and (max-width: 576px) {
    .follow-container {
        padding: 50px 20px;
        &__title {
            font: normal normal bold 24px/29px Montserrat;
            margin-bottom: 10px;
        }
        &__commingsoon {
            font: normal normal normal 13px/22px Montserrat;
        }
        &__hotties-wrapper::-webkit-scrollbar {
            display: none;
        }

        &__button-rows {
            width: 100%;
            margin-top: 26px;
            justify-content: flex-start;
            flex-wrap: nowrap;
            overflow-y: scroll;
            .btn-item {
                font: normal normal 600 14px/22px Montserrat;
                padding: 6px 12px;
                white-space: nowrap;
            }
        }
        &__button-rows::-webkit-scrollbar {
            display: none;
        }
    }
}
