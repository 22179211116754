$base-text-color: white;
@import '../../../../styles/index.scss';

.vip-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 120px;
    .vip-left {
        .title-mobile-screen {
            display: none;
        }
        display: flex;
        justify-content: center;
        img {
            width: 317px;
            height: 479px;
        }
        margin-right: 143px;
    }
    .vip-right {
        color: $base-text-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
            color: var(--unnamed-color-ffffff);
            text-align: left;
            font: normal normal 600 36px/80px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            line-height: 30px;
        }
        .row-buttons {
            display: flex;
            flex-direction: row;
            width: 100%;
            a {
                text-decoration: none;
                color: #fff;
            }
            .get-access-button {
                box-sizing: border-box;
                @include base-button();
                margin-right: 20px;

                font: normal normal 600 15px/22px Montserrat;
                text-transform: uppercase;
                padding: 16px 70px;
                border-radius: 100px;
                white-space: nowrap;
            }
            .learnmore-button {
                border: 1px solid #FF8200;
                border-radius: 100px;

                padding: 14px 89px;
                display: flex;
                font: normal normal medium 15px/22px Montserrat;
                justify-content: center;
                align-items: center;
                background-image: black;
                font-size: 15px;
            }
        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1119px) {
    .vip-container {
        .vip-left {
            flex-direction: column;
            align-items: center;
            img {
                width: 300px;
                height: 450px;
            }
            margin-right: 70px;
        }
        .vip-right {
            h1 {
                font-size: 27px;
            }

            .row-buttons {
                .get-access-button {
                    font-size: 15px;
                    padding: 15px 30px;
                }
                .learnmore-button {
                    font-size: 15px;
                    padding: 15px 30px;
                }
            }
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .vip-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .vip-left {
            margin-right: 0px;
            img {
                width: 250px;
                height: 400px;
            }
        }
        .vip-right {
            align-items: center;

            h1 {
                font-size: 30px;
            }

            .row-buttons {
                justify-content: center;
            //     flex-direction: column;
            //     align-items: center;
            //     .get-access-button {
            //         margin-right: 0px;

            //         // width: 50%;
            //         padding: 15px 30px;

            //         margin-bottom: 10px;
            //     }
            //     .learnmore-button {
            //         box-sizing: border-box;
            //         // width: 50%;
            //         padding: 15px 30px;
            //     }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .vip-container {
        flex-direction: column;
        align-items: center;
        padding: 70px 0px 70px 0px;
        margin-bottom: 0px;

        .vip-left {
            flex-direction: column;
            align-items: center;
            margin-right: 0px;
            img {
                width: 200px;
                height: 350px;
            }
            .title-mobile-screen {
                display: block;
                text-align: center;
                letter-spacing: 1.05px;
                color: #ffffff;
                opacity: 1;
                font-size: 21px;
                font-weight: bold;
                padding: 0 50px;
                margin-bottom: 1rem;
            }
        }
        .vip-right {
            align-items: center;

            h1 {
                font-size: 19px;
            }
            .title-desktop-screen {
                display: none;
            }

            .row-buttons {
                flex-direction: column;
                align-items: center;
                .get-access-button {
                    margin-right: 0px;

                    width: 325px;
                    height: 50px;
                    padding: 5px 30px;
                    font-size: 15px;
                    margin-bottom: 10px;
                }
                .learnmore-button {
                    box-sizing: border-box;
                    width: 325px;
                    height: 50px;
                    padding: 5px 30px;
                    font-weight: 600;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .vip-container {
        flex-direction: column;
        align-items: center;
        padding: 70px 0px 70px 0px;

        .vip-left {
            flex-direction: column;
            align-items: center;
            margin-right: 0px;

            img {
                width: 200px;
                height: 300px;
            }
        }
        .vip-right {
            align-items: center;

            h1 {
                font-size: 19px;
            }
        }
    }
}
