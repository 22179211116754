.navigate-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
        text-align: left;
        font: normal normal normal 17px/22px Montserrat;
        color: #ffffff;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .coming-soon {
            display: inline-block;
            align-items: center;
            width: fit-content;
            background: #2c2c2c;
            border-radius: 5px;
            font-size: 14px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: #939393;
            padding: 2px 10px;
            margin-left: 5px;
            text-transform: uppercase;
        }
    }
    .subtitle {
        font: normal normal normal 15px/22px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.55;
        margin-bottom: 20px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .navigate-container {
        .title {
            font-size: 18px;
            line-height: 25px;
        }
        .subtitle {
            font-size: 12px;
        }
    }
}
@media screen and (max-width: 576px) {
    .navigate-container {
        align-items: center;
        gap: 16px;
        
        .title {
            display: none;
        }
        .subtitle {
            font: normal normal 400 15px/18px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            text-align: center;
            opacity: 1;
            margin-bottom: 0px;
        }
    }
}
