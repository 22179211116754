@import '../../../../styles/index.scss';
.bg-creator {
    background: transparent linear-gradient(121deg, #ff3e3e 0%, #ff7000 100%) 0% 0% no-repeat padding-box;
}
.creator-container {
    padding: 5% 10%;
    @include base-container();
    display: flex;
    flex-direction: row;

    &__left {
        width: 40%;
        opacity: 1;
        height: 100%;
        margin-right: 6%;
        &__wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;

            padding: 10%;
            background: #212127 0% 0% no-repeat padding-box;
            border-radius: 15px;

            .left-title {
                text-align: center;
                font: normal normal 600 19px/22px Montserrat;
                .love {
                    color: #ff3e3e;
                }
            }
            .hr-title {
                width: 5%;
                background-color: #808080;
                height: 2.5px;
                border-style: none;
            }
            .left-body {
                display: flex;
                flex-direction: column;
                // margin-top: 15px;
            }
        }
    }
    &__right {
        width: 60%;
        position: relative;
        &__inline {
            border: 2px solid #ff8383;
            border-radius: 15px;
            // z-index: 1;
            position: absolute;
            top: 10px;
            left: 10px;
            width: 100%;
            height: calc(100% - 14px);
        }

        &__outline {
            border: 2px solid #ff8383;
            border-radius: 15px;
            height: calc(100% - 14px);
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1000;
            &__title {
                margin-top: 65px;
                font: normal normal bold 28px/28px Montserrat;
                color: #ffffff;
                margin-bottom: 9px;
            }
            &__detail {
                padding: 0px 10px;
                text-align: center;
                font: normal normal normal 12px/15px Montserrat;
                color: #ffffff;
                margin-bottom: 36px;
            }
            &__input-list {
                z-index: 1;
                width: 70%;
                display: flex;
                flex-direction: column;
                .creator-input {
                    @include base-input-creator-page();
                    color: white;
                }
                .creator-input::placeholder {
                    // opacity: 0.8;
                    color: #f7bca3;
                }
            }
            &__btn-getintouch {
                margin-top: 49px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                border: 2px solid white;
                text-align: center;
                font: normal normal 600 15px/22px Montserrat;
                color: black;
                padding: 15px 80px;
                border-radius: 100px;
                transition: all 0.3s;
                cursor: pointer;
                &:active {
                    background-color: transparent;
                }
            }
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 992px) {
    .creator-container {
        flex-direction: column;
        align-items: center;

        &__left {
            width: 75%;
            margin-bottom: 20px;
            margin-right: 0px;
        }

        &__right {
            width: 75%;

            &__outline {
                height: 100%;
                width: calc(100% - 14px);
                box-sizing: border-box;
                padding: 10px 22px;
                &__title {
                    margin-top: 50px;
                }
                &__input-list {
                    width: 100%;
                    .creator-input {
                        width: 100%;
                    }
                }
                &__btn-getintouch {
                    width: 100%;
                    margin-top: 30px;
                    margin-bottom: 26px;
                    box-sizing: border-box;
                    padding: 10px 0px;
                }
            }
            &__inline {
                box-sizing: border-box;
                height: 100%;
                width: calc(100% - 14px);
                // height: calc(100% - 14px);
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .creator-container {
        padding: 20px;
        flex-direction: column;
        // width: 100%;

        &__left {
            margin-right: 0px;
            width: 100%;
            margin-bottom: 20px;
            &__wrapper {
                padding: 37px 24px;
                .left-title {
                    font: normal normal 600 19px/22px Montserrat;
                }
                .left-body {
                    .reason-item-container {
                        .reason-title {
                            font: normal normal normal 12px/18px Montserrat;
                        }
                        .reason-description {
                            font: normal normal normal 14px/15px Montserrat;
                        }
                    }
                }
            }
        }
        &__right {
            width: 100%;
            margin-right: 0px;

            &__outline {
                height: 100%;
                width: calc(100% - 14px);
                box-sizing: border-box;
                padding: 10px 22px;
                &__title {
                    font: normal normal bold 22px/28px Montserrat;
                    margin-top: 50px;
                }
                &__detail {
                    font: normal normal normal 12px/14px Montserrat;
                }
                &__input-list {
                    width: 100%;
                    .creator-input {
                        width: 100%;
                        font: normal normal bold 15px/19px Montserrat;
                    }
                }
                &__btn-getintouch {
                    width: 100%;
                    margin-top: 30px;
                    margin-bottom: 26px;
                    font: normal normal 600 15px/22px Montserrat;
                    box-sizing: border-box;
                    padding: 10px 0px;
                }
            }
            &__inline {
                box-sizing: border-box;
                height: 100%;
                width: calc(100% - 14px);
                // height: calc(100% - 14px);
            }
        }
    }
}
