.creator-header {
    position: relative;
    height: 427px;
    background-repeat: no-repeat;
    background-position: top 25% center;
    background-size: cover;
    background-attachment: fixed;

    &__nav {
        width: 100%;

        .btn {
            cursor: pointer;
            border: none;
            color: #000;
            background-color: #fff;

            &-back {
                border-radius: 50%;
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-heart {
                border-radius: 999px;
                display: flex;
                height: 45px;
                justify-content: center;
                align-items: center;
                color: #ff6060;
                padding: 0 50px;

                span {
                    margin-right: 5px;
                    font-weight: 600;
                    font-size: 15px;
                }

                svg {
                    width: 20px;
                }
            }
        }

        .container__content {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    @media screen and (min-width: 577px) and (max-width: 991px) {
        height: 400px;
    }

    @media screen and (max-width: 576px) {
        height: 300px;
        background-attachment: scroll;

        .btn {
            &-back {
                width: 36px;
                height: 36px;
            }

            &-heart {
                height: 36px;
                padding: 0 20px;
            }
        }

        .container__content {
            padding-right: 20px;
        }
    }

    @media screen and (max-width: 375px) {
        height: 186px;

        &__nav {
            top: 58px;
        }
    }
}
