.card-inventory {
    width:100%;
    border-radius: 4px;
    overflow: hidden;
    img {
        width:100%;
    }
    .rarity {
        text-align: center;
        text-transform: capitalize;
        display: block;
        padding: 8px 0px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color:black;
        font-weight: 600;
    }
    .extend {
        padding:24px;
        color:black;
        display: flex;
        flex-direction: column;
        &__price {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
        }
        &__description {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
        &__id {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .diamond-tier {
        background: linear-gradient(241.68deg, #D2F4FF 17.65%, #8AC9EB 46.08%, #A5BEFF 58.6%, #EBDACE 82.63%);
    }
    .diamond-info {
        background: linear-gradient(241.68deg, #F0FBFF 17.65%, #BEE1F4 46.08%, #DBE5FF 58.6%, #F5ECE6 82.63%);
    }
    .gold-tier {
        background: linear-gradient(241.68deg, #FFEBC6 17.65%, #FFC9A3 48.11%, #FCBD5E 82.63%);
    }
    .gold-info {
        background: linear-gradient(241.68deg, #FFFCF5 17.65%, #FFEDE0 48.11%, #FEE4BE 82.63%);
    }
    .silver-tier {
        background: linear-gradient(241.68deg, #FFFFFF 17.65%, #D3D6D9 50.14%, #C1CBD1 82.63%);
    }
    .silver-info {
        background: linear-gradient(241.68deg, #FFFFFF 17.65%, #DCDEE0 50.14%, #EDF0F2 82.63%);
    }
    
}