.card-container {
    width: 100%;
    background: #1a1a1a 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    height: 50px;
    padding: 5px 10px;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 15px;
    box-sizing: border-box;
    .image-tiny {
        width: 30px;
        height: 47px;

        .image-content {
            width: 100%;
            height: 100%;
        }
        margin-right: 20px;
    }
    .icon {
        width: 28px;
        height: 28px;
        margin-right: 20px;
        display: flex;
        &__content {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            margin: auto;
        }
    }
    .name {
        flex: 50%;
        text-align: left;
        font: normal normal normal 16px/19px Montserrat;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .price {
        flex: 10%;
        margin-right: 15px;

        text-align: right;
        font: normal normal normal 16px/19px Montserrat;
        letter-spacing: 0px;
        color: #6f6f6f;
        opacity: 1;
    }
}
@media screen and (max-width: 576px) {
    .card-container {
        height: fit-content;

        .image-tiny {
            width: 30px;
            height: 47px;
        }
        .icon {
            width: 28px;
            height: 28px;
        }
        .name {
            flex: 65%;
            font: normal normal normal 16px/19px Montserrat;
        }
        .price {
            flex: 5%;
            font-size: 12px;
        }
    }
}
