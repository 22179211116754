$base-text-color: white;
@import '../../../../styles/index.scss';

.special-pass-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 120px;
    padding-bottom: 50px;

    &__left {
        color: $base-text-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
            color: var(--unnamed-color-ffffff);
            text-align: left;
            font: normal normal 600 36px/80px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            line-height: 30px;
        }
        span {
            position: relative;
            color: var(--unnamed-color-ffffff);
            text-align: left;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            margin-top: 7px;
            margin-bottom: 38px;
            font-size: 15px;
        }
        .row-buttons {
            display: flex;
            flex-direction: row;
            width: 100%;
            .get-access-button {
                box-sizing: border-box;
                @include base-button();
                margin-right: 20px;

                font: normal normal 600 15px/22px Montserrat;
                text-transform: uppercase;
                padding: 16px 70px;
                border-radius: 100px;
                white-space: nowrap;
            }
            .learnmore-button {
                border: 1px solid #FF8200;
                border-radius: 100px;
                padding: 14px 89px;
                display: flex;
                font: normal normal medium 15px/22px Montserrat;
                justify-content: center;
                align-items: center;
                background-image: black;
                font-size: 15px;
                cursor: pointer;
            }
        }
    }

    &__right {
        .title-mobile-screen {
            display: none;
        }
        display: flex;
        justify-content: center;
        img {
            width: 427px;
            height: 479px;
        }
        margin-left: 143px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1440px) {
    .special-pass-container {
        &__left {
            .row-buttons {
                .get-access-button {
                    font-size: 15px;
                    padding: 15px 30px;
                }
                .learnmore-button {
                    font-size: 15px;
                    padding: 15px 30px;
                }
            }
        }
        &__right {
            flex-direction: column;
            align-items: center;
            img {
                width: 300px;
                height: 450px;
            }
            margin-left: 70px;
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .special-pass-container {
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
        &__left {
            align-items: center;

            h1 {
                font-size: 30px;
                text-align: center;
            }
            span {
                font-size: 14px;
                text-align: center;
            }
            .row-buttons {
                flex-direction: column;
                align-items: center;
                .get-access-button {
                    margin-right: 0px;

                    // width: 50%;
                    padding: 15px 30px;

                    margin-bottom: 10px;
                }
                .learnmore-button {
                    box-sizing: border-box;
                    // width: 50%;
                    padding: 15px 30px;
                }
            }
        }
        &__right {
            margin-left: 0px;
            img {
                width: 250px;
                height: 400px;
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .special-pass-container {
        flex-direction: column-reverse;
        align-items: center;
        padding: 70px 0px 70px 0px;
        margin-top: 0px;
        
        &__left {
            align-items: center;

            h1 {
                font-size: 19px;
            }
            .title-desktop-screen {
                display: none;
            }
            span {
                font-size: 14px;
                text-align: center;
            }
            .row-buttons {
                flex-direction: column;
                align-items: center;
                .get-access-button {
                    margin-right: 0px;

                    width: 325px;
                    height: 50px;
                    padding: 5px 30px;
                    font-size: 15px;
                    margin-bottom: 10px;
                }
                .learnmore-button {
                    box-sizing: border-box;
                    width: 325px;
                    height: 50px;
                    padding: 5px 30px;
                    font-weight: 600;
                }
            }
        }
        &__right {
            flex-direction: column;
            align-items: center;
            margin-left: 0px;
            img {
                width: 200px;
                height: 350px;
            }
            .title-mobile-screen {
                display: block;
                text-align: center;
                letter-spacing: 1.05px;
                color: #ffffff;
                opacity: 1;
                font-size: 21px;
                font-weight: bold;
                padding: 0 50px;
                margin-bottom: 1rem;
            }
        }
    }
}
