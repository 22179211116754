@mixin base-border {
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
}

.feature-container {
    padding: 128px;
    .feature-wrapper {
        @include base-border();
        color: white;
        display: flex;
        justify-content: space-around;
        padding: 120px 0px;
        align-items: baseline;
        .feature-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .featureName {
                color: var(--unnamed-color-ffffff);
                text-align: center;
                font: normal normal 600 12px/18px Montserrat;
                letter-spacing: 0.6px;
                color: #ffffff;
                opacity: 1;
                text-transform: uppercase;
                margin-top: 10px;
            }
            .content {
                text-align: center;
                font: normal normal 600 18px/18px Montserrat;
                letter-spacing: 0px;
                color: #ff3e3e;
                opacity: 1;
            }
            img {
                width: 90px;
            }
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
    .feature-container {
        padding: 0;
        margin-top: 8%;

        .feature-wrapper {
            padding: 5%;
            flex-wrap: wrap;
            .feature-item {
                margin: 10px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .feature-container {
        padding: 0px;
        border-bottom: 1px solid #4d4d4d;

        .feature-wrapper {
            flex-wrap: wrap;
            gap: 42px;
            margin: 80px 20px 80px 20px;

            @include base-border();
            color: white;
            display: flex;
            justify-content: space-around;
            padding: 39px 0px;
            .feature-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;

                .featureName {
                    color: var(--unnamed-color-ffffff);
                    text-align: center;
                    font: normal normal 600 12px/18px Montserrat;
                    letter-spacing: 0.6px;
                    color: #ffffff;
                    opacity: 1;
                    text-transform: uppercase;
                    margin-top: 10px;
                }
                .content {
                    text-align: center;
                    font: normal normal 600 18px/18px Montserrat;
                    letter-spacing: 0px;
                    color: #ff3e3e;
                    opacity: 1;
                }
            }
        }
    }
}
