.hotties-item-container {
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 42px;
    overflow: hidden;

    .avatar {
        height: 100px;
        width: 100px;
        margin-bottom: 11px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .name {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        font: normal normal normal 15px/19px Montserrat;
        color: var(--unnamed-color-ffffff);
        text-align: center;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        white-space: nowrap;
    }

    a {
        text-decoration: none;
    }
}

@media screen and (max-width: 375px) {
    .hotties-item-container {
        .name {
            text-align: center;
            font-size: smaller;
            letter-spacing: 0px;
        }
    }
}
