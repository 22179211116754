.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: rgba($color: #000000, $alpha: 0.9);
}
.title {
    font: normal normal 600 32px/80px Montserrat;
    color: white;
    display: block;
}

.account-img {
    width: 36px;
    height:36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 50%;
    cursor: pointer;
    transition:all .3s;
    background-color: black;
}