@import '../../../../styles/index.scss';
.create-account-container {
    width: 100%;
    background: #212127 0% 0% no-repeat padding-box;

    .create-account-wrapper {
        background: #212127 0% 0% no-repeat padding-box;
        padding: 70px 120px;
        padding-bottom: 0px;
        margin: 0 auto;
        box-sizing: border-box;
        max-width: 1440px;

        &__content {
            padding-bottom: 43px;
            padding-top: 66px;

            border: 2px solid #555555;
            opacity: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon {
                margin-bottom: 18px;
                .content {
                    width: 30px;
                    height: 40px;
                }
            }
            .title {
                text-align: center;
                font: normal normal bold 14px/18px Montserrat;
                letter-spacing: 1.4px;
                color: #ffffff;
                margin-bottom: 42px;
            }
            .btn-create-account {
                @include base-button();
                font-family: 'Montserrat';
                border-radius: 100px;
                padding: 15px 70px;
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .create-account-container {
        width: 100%;
        .create-account-wrapper {
            padding: 30px 20px;
            &__content {
                padding-bottom: 20px;
                padding-top:10px;
                .icon {
                    margin-top: 20px;
                    margin-bottom: 10px;
                    .content {
                        width: 30px;
                        height: 40px;
                    }
                }
                .title {
                    text-align: center;
                    font: normal normal bold 14px/18px Montserrat;
                    letter-spacing: 1.4px;
                    color: #ffffff;
                    margin-bottom: 30px;
                }
                .btn-create-account {
                    @include base-button();
                    font: normal normal bold 15px/22px Montserrat;
                    border-radius: 100px;
                    padding: 16px 69px;
                }
            }
        }
    }
}
