.search-mobile-container {
    width: 100%;
    padding: 10px;
    background-color: #000000;
    border-bottom: 1px solid #212127;
    display: none;

    box-sizing: border-box;
}
@media screen and (max-width: 768px) {
    .search-mobile-container {
        display: block;
        position: sticky;
        z-index: 9;
        top: 69px;
    }
}
