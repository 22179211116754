@import '../../';
.authenticate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    .overlay {
        opacity: 1;
    }
    input {
        color: white;
        padding: 0px 16px;
    }
    &__form {
        position: relative;
        width: 440px;
        background: #212127;
        /* Neutral/N800 */

        border: none;
        box-sizing: border-box;
        /* Shadow-100 */

        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        &--thirdparty {
            display: flex;
            flex-direction: column;
            padding:15px;
            button {
                font-family: 'Montserrat' !important;
                border-radius: 100px !important;
                padding:10px 0px;
                width:100% !important;
                color:white !important;
                overflow: hidden !important;
                font-size: 15px !important;
                font-weight: 900 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize !important;
                i {
                    display: block;
                    margin-right: 25px !important;
                }
            }
            & > button {
                margin-top:10px;
                background-color: #EA4336 !important;
                    div {
                        background-color: transparent !important;
                    }
                    span {
                        font-weight: 600 !important;
                    }
            }
            
        }
        .switch-tab {
            position: absolute;
            bottom: -30px;
            left: 0px;
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height, or 150% */

            text-align: center;
            margin-top: 16px;
            display: block;
            span {
                font-weight: 600;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        svg {
            color: white;
            justify-self: flex-end;
            align-self: flex-end;
        }
        .title {
            margin: 0;
            padding: 0;
            line-height: 40px;
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 24px;
        }
        .form-container {
            margin-top: 20px;
            padding: 0px 15px;
            position: relative;

            .border {
                width: 100%;
                height: 1px;
                background: #404040;
            }

            .border-text {
                position: absolute;
                top: -9px;
                left: 45%;
                padding: 0px 12px;
                background: #212127;
                color: #414141;
                font-size: 13px;
                font-weight: 600;
            }

            .form-item {
                display: flex;
                flex-direction: column;
                margin-top: 16px;
    
                .label {
                    display: flex;
                    justify-content: space-between;
                    
                    .label-name {
                        font-size: 13px;
                    }
    
                    .label-error {
                        font-size: 11px;
                        color: #FF5555;
                    }
                }
                .input-field {
                    display: flex;
                    align-items: center;
                    margin-top: 5px;
                    position: relative;
                    box-sizing: border-box;
    
                    input {
                        width: 100%;
                        height: 50px;
                        font-size: 14px;
                        background-color: transparent;
                        border: none;
                        padding-right: 40px;
                        background: #1B1B1D;
                        border: 1px solid #404040;
                        border-radius: 8px;

                        &:focus {
                            border-color: #0082F3;
                        }
                    }
        
                    .input-error-suffix {
                        color: #FF5555;
                        align-self: center;
                        position: absolute;
                        right: 12px;
                    }
                }
            }

            .forgot-password {
                margin-top: 8px;
                font-size: 13px;
                font-weight: 600;
                font-family: 'Montserrat';
                color: #818183;
                text-align: center;
                margin-bottom: 60px;
                cursor: pointer;
            }
        }
        .checkbox-field {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-top:10px;
            padding-top:2px;
            /* Hide the browser's default checkbox */
            input {
                position: absolute;
                cursor: pointer;
                height: 0;
                width: 0;
                height: 20px;
                width: 20px;
                top:0;
                left:0;
                opacity: 0;
                z-index: 1;
            }
              
              /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                border:1px solid #818183;
                border-radius: 3px;
            }
              
              /* On mouse-over, add a grey background color */
            &:hover input ~ .checkmark {
                background-color: rgb(99, 99, 99);
            }
              
              /* When the checkbox is checked, add a blue background */
            input:checked ~ .checkmark {
            }
              
              /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
              }
              
              /* Show the checkmark when checked */
            input:checked ~ .checkmark:after {
                display: block;
            }
              
              /* Style the checkmark/indicator */
            .checkmark:after {
                left: 6px;
                top: 2px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            label {
                color:#ACACAC;
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                font-weight: 600;

            }
            label a{
                color:#ACACAC;
            }
            .age-warning {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .checkbox-field-error {
            margin-top: 5px;
            font-size: 11px;
            color: #FF5555;
        }
        .btn-submit {
            width: 100%;
            height: 51px;
            margin-top: 20px;
            padding: 8px;
            flex: 1;
            @include base-button;
            border-radius: 100px;
            color: #fff;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            border: none;
        }

        .btn-submit:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
}

.verify-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    height: 443px;
    background: #212127;
    border-radius: 20px;
    padding: 50px;
    display: flex;
    flex-direction: column;

    .back-icon {
        color: #5D5D5D;
        cursor: pointer;
        position: absolute;
        top: 30px;
        left: 20px
    }

    .close-icon {
        color: #5D5D5D;
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 20px;
    }

    .form-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .verify-modal-top {
        text-align: center;
        margin-top: 10px;
        overflow: hidden;

        &:hover {
            overflow: auto;
        }

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 12px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            background-color: #464646;
        }

        &__title {
            margin-bottom: 20px;
            font: normal normal 600 15px/19px Montserrat;
        }
    
        &__subtitle {
            font: normal normal normal 14px/18px Montserrat;
        }

        .large-content {
            padding: 0px 24px;
        }
    }

    .verify-modal-body {
        margin-top: 50px;

        .form-item {
            margin-bottom: 20px;
        }

        &__label {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font: normal normal normal 13px/15px Montserrat;

            .label-error {
                font-size: 11px;
                color: #FF5555;
                margin-left: auto;
            }
        }

        &__input {
            display: flex;
            margin-top: 5px;
            position: relative;

            input {
                width: 100%;
                height: 50px;
                background: transparent;
                border: none;
                color: #fff;
                font-size: 14px;
                border: 1px solid #404040;
                border-radius: 8px;
                background: #1B1B1D;
                padding-left: 16px;
                padding-right: 40px;

                &:focus {
                    border-color: #0082F3;
                }
            }
        }

        .input-error-suffix {
            color: #FF5555;
            align-self: center;
            position: absolute;
            right: 12px;
        }
    }

    .verify-modal-bottom {
        margin-top: auto;

        .btn-submit {
            width: 100%;
            height: 51px;
            margin-top: 20px;
            padding: 8px;
            flex: 1;
            @include base-button;
            border-radius: 100px;
            color: #fff;
            font: normal normal 600 15px/22px Montserrat;
            line-height: 24px;
            border: none;
        }
    }

    .text-action {
        font-size: 13px;
        font-size: 600;
        font-family: 'Montserrat';
        color:#818183;
        margin-top: 6px;
        cursor: pointer;
        text-align: center;
    }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .authenticate {
        input {
            color: white;
            padding: 0px 16px;
        }
        &__form {
            width: 380px;

            padding: 20px;

            svg {
                color: white;
                justify-self: flex-end;
                align-self: flex-end;
            }
            .title {
                font-size: 22px;
            }
            .input-field {
                margin-top: 12px;
                .label {
                    font-size: 13px;
                }
                input {
                    margin-top: 4px;
                    font-size: 14px;
                    height: 30px;
                }
            }
            .checkbox-field {
                label {
                    font-size: 11px;
                }
            }
        }
    }
}
@media screen and (max-width: 575px) {
    .authenticate {
        z-index: 1000;
        &__form {
            width: 100%;
            height: 100%;
            overflow: auto;

            svg {
                color: white;
                justify-self: flex-end;
                align-self: flex-end;
            }
            .title {
                font-size: 20px;
            }
            .form-container {
                .form-item {
                    .input-field {
                        margin-top: 10px;
                        .label {
                            font-size: 13px;
                        }
                        input {
                            margin-top: 4px;
                            font-size: 13px;
                            height: 48px;
                        }
                    }
                }
                .forgot-password {
                    margin-top: 26px;
                }
            }
            .checkbox-field {
                label {
                    font-size: 11px;
                }
            }

            .switch-tab {
                position: relative;
                margin-bottom: 24px;
            }
        }
        .btn-submit {
            padding: 5px;

            font-size: 14px;
            line-height: 24px;
        }
    }

    .verify-modal {
        width: 100%;
        height: 100%;
        padding: 0px;

        .verify-modal-top {
            margin-top: 35px;

            &__title {
                margin-bottom: 57px;
            }
        }

        .verify-modal-body {
            padding: 0px 24px;

            .text-action {
                margin-top: 34px;
            }
        }

        .verify-modal-bottom {
            margin-top: 50px;
            padding: 0px 25px;
        }
    }
}
