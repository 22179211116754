.friends {
    padding: 6rem 0;
    border-bottom: 1px solid #4d4d4d;

    h2 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 35px;
        text-transform: uppercase;
    }

    &__hotties {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 83px;
        padding-bottom: 1rem;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            height: 6px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #464646; 
        }
    }
}
