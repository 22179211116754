.creator-infos {
    .container__content {
        display: flex;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        justify-items: center;
        align-items: start;
    }

    &__avatar-group {
        text-align: center;
        grid-area: 1 / 1 / span 1 / span 1;

        .avatar-img {
            border-radius: 500px;
            margin-top: -50%;
            border: 7px solid #000;
            width: 133px;
            height: 133px;
            margin-bottom: 13px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__display-name {
        font-size: 19px;
        font-weight: 600;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: visible;
        white-space: nowrap;

        img {
            margin-left: 5px;
            width: 18px;
            height: 18px;
        }

        span {
            max-width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__username {
        font-size: 16px;
        color: #d9d9d9;
        margin: 3px auto 15px;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__subscribe {
        cursor: pointer;
        background-color: #3C3C3C;
        color: #FF3E3E;
        font-weight: 600;
        font-size: 15px;
        padding: 10px 50px;
        border-radius: 999px;
        border: none;
        font-family: 'Montserrat';
    }
    
    &__btn-dirty-dozen {
        white-space: nowrap;
        grid-area: 2 / 1 / span 1 / span 1;
        padding: 3px 18px;
        background-color: #292929;
        color: #ff3e3e;
        font-size: 14px;
        font-weight: medium;
        border-radius: 5px;
        border: none;
        margin-top: 30px;
    }

    &__socials {
        grid-area: 1 / 2 / span 1 / span 1;
        padding: 27px 20px;
        display: flex;

        > a {
            display: block;

            svg {
                width: 22px;
                height: 22px;
                color: #d9d9d940;
            }

            &:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    &__other {
        flex-grow: 1;
        padding: 27px 20px;
        text-align: center;
    }

    &__account-infos {
        display: flex;
        justify-content: center;

        .drops {
            text-align: center;
            margin: 0 20px;

            > p:nth-child(1) {
                font-size: 17px;
                font-weight: bold;
            }

            > p:nth-child(2) {
                font-size: 13px;
            }
        }
    }

    &__personal-infos {
        margin-top: 34px;
        border-radius: 1000px;
        background-color: #1b1b1c;
        font-size: 14px;
        padding: 10px 33px;
        display: flex;
        justify-content: space-evenly;

        span {
            display: flex;
            align-items: center;
            white-space: nowrap;

            img {
                margin-right: 8px;
            }
        }
    }

    &__trending-tags {
        padding: 27px 20px;

        h4 {
            font-size: 12px;
            font-weight: bold;
            color: #ff3e3e;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                width: 9px;
                height: 13px;
                margin-right: 4px;
            }
        }

        .tags {
            padding-top: 10px;
        }

        .tag {
            padding: 4px 10px;
            font-size: 12px;
            font-weight: medium;
            margin-right: 10px;
            margin-bottom: 10px;
            background-color: #3c3c3c;
            border-radius: 4px;
        }
    }

    @media screen and (max-width: 950px) {
        .container__content {
            flex-direction: column;
        }

        &__grid {
            padding-left: 10px;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
        }

        &__avatar-group {
            grid-area: 1 / 2 / span 1 / span 1;
        }

        &__subscribe {
            margin-top: 25px;
        }

        &__btn-dirty-dozen {
            grid-area: 1 / 1 / span 1 / span 1;
            margin-top: 18px;
        }

        &__socials {
            grid-area: 1 / 3 / span 1 / span 1;
        }

        &__trending-tags {
            text-align: center;
        }
    }

    @media screen and (max-width: 595px) {
        &__avatar-group {
            .avatar-img {
                width: 100px;
                height: 100px;
                border-width: 5px;
                margin-top: -42%;
            }
        }

        &__subscribe {
            display: block;
            font-size: 12px;
            padding: 3px 20px;
            margin-top: 0px;
        }

        &__btn-dirty-dozen {
            font-size: 12px;
            padding: 3px 8px;
        }

        &__socials {
            padding: 18px 10px;

            a {
                svg {
                    width: 17px;
                    height: 17px;
                    fill: #d9d9d9;
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &__display-name {
            font-size: 16px;
            max-width: 110px;
        }

        &__username {
            font-size: 14px;
            margin-bottom: 0;
        }

        &__other {
            padding-top: 22px;
            padding-bottom: 18px;
        }

        &__personal-infos {
            margin-top: 20px;
            flex-wrap: wrap;

            span {
                flex-basis: 45%;

                &:first-child {
                    margin-bottom: 5px;
                }
            }
        }

        &__trending-tags {
            padding-top: 0;

            h4 {
                justify-content: center;
            }
        }
    }
}
