@import '../../../../styles/index.scss';
$base-background-color: #121212;
$base-coming-soon-color: #2c2c2c;
.bg-drop {
    background: #121212 0% 0% no-repeat padding-box;
    padding-top: 127px;
    padding-bottom: 177px;
    padding-left: 126px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.drops-container {
    // background-color: $base-background-color;
    max-width: 1440px;
    width: 100vw;
    overflow: hidden;
    &__header {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        .title {
            text-transform: uppercase;
            font-weight: 600;
            margin-right: 20px;
        }
        .comming-soon {
            @include base-comming-soon();
            text-align: center;
            font: normal normal 600 14px/22px Montserrat;
            letter-spacing: 0px;
            color: #939393;
            padding: 2px 10px;
        }
    }
    &__list {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-x: auto;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
            height: 6px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #464646; 
        }
    }
}
@media screen and (max-width: 576px) {
    .bg-drop {
        padding: 68px 20px;

        .drops-container {
            padding: 0;
            &__header {
                margin-bottom: 21px;

                .title {
                    font: normal normal bold 24px/29px Montserrat;
                }
                .comming-soon {
                    font: normal normal 600 12px/22px Montserrat;
                }
            }
            &__list::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
