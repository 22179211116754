.container {
    display: flex;
    align-items: center;

    &__content {
        position: relative;
        width: 100vw;
        // 1366px
        max-width: 1440px;
        // overflow: hidden;
        padding-left: 37px;
        padding-right: 37px;
        margin: 0 auto;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 595px) {
    .container {
        &__content {
            position: relative;
            width: 100vw;
            // 1366px
            max-width: 1440px;
            padding-left: 10px;
            padding-right: 10px;
            margin: 0 auto;
            box-sizing: border-box;
        }
    }
}
