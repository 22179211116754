.creator-drops {
    padding: 10rem 0;
    border-bottom: 1px solid #4d4d4d;

    &__heading {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 30px;
        text-transform: uppercase;

        h1 {
            font-size: 32px;
            color: #454545;
        }

        p {
            color: #fff;
        }
    }

    &__drops {
        display: flex;
        overflow-x: scroll;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
            height: 6px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #464646; 
        }

        img {
            width: 322px;
            margin-right: 32px;
        }
    }

    @media screen and (max-width: 595px) {
        padding: 1rem 0 3rem;

        &__heading {
            h1, p {
                font-size: 24px;
            }
        }

        &__drops {
            img {
                width: 294px;
            }
        }
    }
}
