@import '../../../../styles/index.scss';
.begin-container {
    display: flex;
    flex-direction: row;

    margin-top: 127px;
    padding-left: 10px;
    padding-right: 50px;

    .begin-left {
        width: calc(100% - 593px);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin-bottom: -40px;
        h1 {
            font: normal normal bold 42px/80px Montserrat;
            line-height: 50px;
            text-align: left;
        }

        .description {
            font: normal normal normal 14px/22px Montserrat;
            width: 80%;
            letter-spacing: 0px;
            margin-top: 14px;
            margin-bottom: 20px;
        }
        a {
            text-decoration: none;
            color: #fff;
        }
        .get-access-button {
            @include base-button();
            font: normal normal 600 15px/22px Montserrat;
            text-transform: uppercase;
            padding: 16px 70px;
            border-radius: 100px;
            white-space: nowrap;
        }
    }
    .begin-right {
        color: white;
        width: 593px;
        height: 399px;

        img {
            width: 100%;
            height: 100%;
        }

        .begin-right-image {
            width: 100%;
            height: 400px;
            background-color: #313131;
            border-radius: 20px;
        }
    }
}
@media screen and (min-width: 1120px) and (max-width: 1440px) {
    .begin-container {
        margin-top: 50px;
        padding-left: 0px;
        padding-right: 0px;

        .begin-left {
            width: 70%;

            h1 {
                font-size: 32px;
            }

            .description {
                font-size: 14px;
            }
        }

        .begin-right {
            width: 30%;
            height: 300px;
        }
    }
}
@media screen and (min-width: 769px) and (max-width: 1119px) {
    .begin-container {
        margin-top: 50px;
        padding-left: 0px;
        padding-right: 0px;

        .begin-left {
            width: 60%;

            h1 {
                font-size: 30px;
            }

            .description {
                font-size: 14px;
            }
        }

        .begin-right {
            width: 40%;
            height: 250px;
        }
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .begin-container {
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        flex-direction: column-reverse;
        .begin-left {
            width: 100%;
            margin-bottom: 30px;
            h1 {
                font-size: 30px;
            }
            span {
                font-size: 15px;
            }
            padding: 0;
            .description {
                width: 100%;
            }
        }
        .begin-right {
            width: 331px;
            height: 225px;
            margin-top: 10%;
            margin-bottom: 30px;
            align-self: center;
        }
    }
}
@media screen and (max-width: 576px) {
    .begin-container {
        margin-top: 0px;
        padding: 10px;
        flex-direction: column-reverse;
        .begin-left {
            width: 100%;
            margin-bottom: 20px;
            h1 {
                font: normal normal bold 24px/29px Montserrat;
                letter-spacing: 0.48px;
                color: #ffffff;
                opacity: 1;
                margin-bottom: 10px;
            }
            .description {
                width: 100%;
                margin-bottom: 30px;
            }
            .get-access-button {
                padding: 10px 20px;
                height: 50px;
                font-weight: bold;
                width: 100%;
                box-sizing: border-box;
            }
        }
        .begin-right {
            width: 325px;
            height: 219px;
            margin-top: 27px;
            margin-bottom: 30px;
            align-self: center;
            img {
                width: 100%;
            }
        }
    }
}
